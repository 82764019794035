// Import Vue
import Vue from 'vue';
// import Dom7 from 'dom7';

// Import Framework7
import Framework7 from './framework7-custom.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// var $$ = Dom7;
// // Loading flag
// var allowInfinite = true;
// // Last loaded index
// var lastItemIndex = $$('.list li').length;
// // Max items to load
// var maxItems = 200;
//
// // Append items per load
// var itemsPerLoad = 20;
//
// // Attach 'infinite' event handler
// $$('.infinite-scroll-content').on('infinite', function () {
//   // Exit, if loading in progress
//   if (!allowInfinite) return;
//
//   // Set loading flag
//   allowInfinite = false;
//
//   // Emulate 1s loading
//   setTimeout(function () {
//     // Reset loading flag
//     allowInfinite = true;
//
//     if (lastItemIndex >= maxItems) {
//       // Nothing more to load, detach infinite scroll events to prevent unnecessary loadings
//       app.infiniteScroll.destroy('.infinite-scroll-content');
//       // Remove preloader
//       $$('.infinite-scroll-preloader').remove();
//       return;
//     }
//
//     // Generate new items HTML
//     var html = '';
//     for (var i = lastItemIndex + 1; i <= lastItemIndex + itemsPerLoad; i++) {
//       html += '<li>Item ' + i + '</li>';
//     }
//
//     // Append new items
//     $$('.list ul').append(html);
//
//     // Update last loaded index
//     lastItemIndex = $$('.list li').length;
//   }, 1000);
// });
// console.log(`lastItemIndex`, lastItemIndex);


// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});
