<template>
  <f7-page
    name="home"
    hide-toolbar-on-scroll
    infinite
    :infinite-distance="30"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <!-- Subnavbar -->
      <f7-subnavbar :inner="false" >
        <f7-link class="auction-title" popover-open=".auction-menu" style="margin-left:2%;">
          <span>{{ this.auction }}</span>
          <i class="f7-icons size-3vw">chevron_down</i>
        </f7-link>
      </f7-subnavbar>
      <!-- End of Subnavbar -->

      <!-- Disabled
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left> -->
      <f7-nav-title sliding>
        <img data-src="static/Pickles-Auctions.png" src="static/Pickles-Auctions.png" class="lazy" />
      </f7-nav-title>
      <!-- Disabled
      <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right> -->
    </f7-navbar>
    <!-- End of Top Navbar -->

    <!-- Auction Menu -->
    <f7-popover class="auction-menu" backdrop>
      <f7-list v-for="(auction, index) in auctions" v-bind:key="index">
        <f7-list-button @click="switchAuction(auction.id)" popover-close :title="auction.name"></f7-list-button>
      </f7-list>
    </f7-popover>
    <!-- End of Auction Menu -->

    <!-- Floating Action Button -->
    <f7-fab position="right-bottom" slot="fixed">
      <i class="f7-icons">question</i>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button
          label="Important Notes"
          @click="notesOpened = true"
          >
          <i class="f7-icons">info</i>
        </f7-fab-button>
        <f7-fab-button
          v-if="canShare"
          @click="share()"
          label="Share"
          >
          <i class="material-icons">share</i>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
    <!-- End of Floating Action Button -->

    <!-- Important Notes sheet -->
    <f7-sheet style="height:50%;" class="important-notes" :opened="notesOpened" @sheet:closed="notesOpened = false">
      <f7-toolbar>
        <div class="left"></div>
        <div class="right">
          <f7-link sheet-close>Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-block>
          <h2>Important Notes</h2>
          <p v-html="importantNotes"></p>
          <!-- ... -->
        </f7-block>
      </f7-page-content>
    </f7-sheet>
    <!-- end of Important Notes sheet -->

    <!-- Photo Browser -->
    <f7-photo-browser :photos="photos" ref="photos" theme="dark" type="standalone" :swiper="{ lazy:{ enabled: false } }" />
    <!-- End of Photo Browser -->

    <!-- Loading Skeleton -->
    <f7-list media-list v-if="loading">
      <f7-list-item
        v-for="n in 5"
        :key="n"
        class="skeleton-text skeleton-effect-blink"
        title="Full Name"
        subtitle="Position"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit. Aliquam vestibulum vitae lorem ac iaculis. Praesent nec pharetra massa, at blandit lectus. Sed tincidunt, lectus eu convallis elementum, nibh nisi aliquet urna, nec imperdiet felis sapien at enim."
        >
        <f7-skeleton-block style="width: 40px; height: 40px; border-radius: 50%" slot="media"></f7-skeleton-block>
      </f7-list-item>
    </f7-list>
    <!-- End of Loading Skeleton -->

    <!-- Cards loop for vehicle -->
    <f7-card
      v-for="(item, index) in items"
      v-bind:key="index"
    >
      <div @click="loadPhotos(item.id)">
      <f7-card-header
        class="vehicle-card-header2"
        valign="bottom"
        v-bind:style="{
          '--background-before': 'url(' + config.resizerPrefix + item.image + ')',
          '--background-after': 'url(' + config.resizerPrefix + item.image + ')',
          }"
        >
        <span id="lot_num" class="lot-num">{{ item.lot_num }}</span>
        <span class="short-desc">{{ item.name }}</span>
      </f7-card-header>
      </div>
      <f7-card-content :padding="false">
        <!-- Disable thumbnail swiper
        <f7-row no-gap>
          <f7-col>
            <f7-swiper pagination :params="{ slidesPerView: 3, spaceBetween: 2 }">
              <f7-swiper-slide
                v-for="(image, index) in item.images"
                v-bind:key="index">
                <img
                  @click="loadPhotos(item.id, index)"
                  :src="item.images[index]"
                  class="lazy lazy-fade-in thumbs" />
              </f7-swiper-slide>
            </f7-swiper>
          </f7-col>
        </f7-row> -->
        <f7-row>
          <f7-col v-bind:class=
            "{
              'vehicle-details': true,
              'pos-draft': !posFinal,
              'pos-final': posFinal,
            }"
            >
            <!-- even -->
            <f7-list
              v-for="(value, field) of item.displayColumnsFixed[1]"
              v-bind:key="field"
              no-hairlines-md
              >
              <f7-list-input floating-label readonly type="text"
                :label="field"
                :value="value"
                outline
                />
            </f7-list>
          </f7-col>
          <f7-col>
            <!-- odd -->
            <f7-list
              v-for="(value, field) of item.displayColumnsFixed[2]"
              v-bind:key="field"
              no-hairlines-md
              >
              <f7-list-input floating-label readonly type="text"
                :label="field"
                :value="value"
                outline
                />
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-list accordion-list>
              <f7-list-item accordion-item
                class="show-more"
                >
                <f7-accordion-content>

                  <f7-row>
                    <f7-col class="vehicle-details">
                      <!-- even -->
                      <f7-list
                        v-for="(value, field) of item.displayColumnsDynamic[1]"
                        v-bind:key="field"
                        no-hairlines-md
                        >
                        <f7-list-input floating-label readonly type="text"
                          :label="field"
                          :value="value"
                          outline
                          />
                      </f7-list>
                    </f7-col>
                    <f7-col>
                      <!-- odd -->
                      <f7-list
                        v-for="(value, field) of item.displayColumnsDynamic[2]"
                        v-bind:key="field"
                        no-hairlines-md
                        >
                        <f7-list-input floating-label readonly type="text"
                          :label="field"
                          :value="value"
                          outline
                          />
                      </f7-list>
                    </f7-col>
                  </f7-row>
                  <f7-row v-if="item.inspection_remarks">
                    <f7-col>
                      <div class="item-content item-input item-input-outline item-input-with-value">
                        <div class="item-inner">
                          <div class="item-title item-label item-floating-label">
                            Inspection Remarks
                          </div>
                          <f7-block class="auction-remarks item-input-wrap padding-top-half">
                            {{ item.inspection_remarks || 'n/a' }}
                          </f7-block>
                          </div>
                      </div>
                    </f7-col>
                  </f7-row>
                  <f7-row v-if="item.auction_remarks
                  && (
                    item.auction_remarks.trim().match(/-/g) &&
                    item.auction_remarks.trim().length !== item.auction_remarks.trim().match(/-/g).length
                    )">
                    <f7-col>
                      <div class="item-content item-input item-input-outline item-input-with-value">
                        <div class="item-inner">
                          <div class="item-title item-label item-floating-label">
                            Auction Remarks
                          </div>
                          <f7-block class="auction-remarks item-input-wrap padding-top-half">
                            {{ item.auction_remarks || 'n/a' }}
                          </f7-block>
                          </div>
                      </div>
                    </f7-col>
                  </f7-row>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>
    <!-- End of Cards loop for vehicle -->

    <!-- Footer Toolbar-->
    <f7-toolbar class="toolbar toolbar-bottom">
      <f7-block class="justify-content-space-evenly">
        <f7-row no-gap>
          <f7-col>
            Copyright @ 2020 <a href="/external/https://www.pickles.my/" class="tab-link">Pickles</a> Inc.
            <br />
            All rights reserved.
          </f7-col>
          <f7-col class="display-inline-flex">
            <f7-row>
              <f7-col class="text-align-center">
                Phone
                <a href="/external/tel:60166999170">+60166999170</a>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col class="text-align-center">
                WhatsApp
                <a href="/external/https://wa.me/60125553727">+60125553727</a>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
      </f7-block>
      <!-- <p style="font-size:50%;">
        <b>Pickles Asia</b> (Company no. 1231292-D)<br />
        Vertical Office Suites<br />
        Tower B, Suite 22-6,<br />
        No. 8, Jalan Kerinchi,<br />
        59200, Kuala Lumpur.
      </p> -->
    </f7-toolbar>
    <!-- End of Footer Toolbar-->

  </f7-page>
</template>
<script>
export default {
  data() {
    return {
      baseUrl: 'https://api.ams.pickles.my/api',
      loading: true,
      auction: 'Loading...',
      auctions: [],
      currentAuctionId: null,
      allowInfinite: true,
      showPreloader: false,
      items: [],
      page: 1,
      perpage: 5,
      count: 0,
      vlData: {
        items: [],
      },
      photos: [],
      images: {},
      notesOpened: false,
      posFinal: false,
      importantNotes: null,
      config: {
        resizerPrefix: `https://d37znxaa4eaqnx.cloudfront.net/production/bypass/?w=${screen.width}&u=`,
      }
    }
  },
  async mounted() {
    const self = this;
    window.debug = self;
    window.addEventListener('online', async (e) => {
      self.$f7.dialog.create({
        title: 'Internet Connectivity',
        text: 'Welcome back online!',
        buttons: [
          {
            text: 'OK',
          }
        ]
      }).open();
      self.loading = true;
      await self.loadItems();
      setTimeout(function () {
        self.$f7.dialog.close();
      }, 3000);
    });

    window.addEventListener('offline', async (e) => {
      self.$f7.dialog.create({
        title: 'Internet Connectivity',
        text: 'You are currently offline.',
      }).open();
      self.loading = false;
    });

    await self.loadEvents();
    await self.loadItems();
  },
  created() {
  },
  methods: {
    async loadEvents() {
      const self = this;
      const eventsUrl = `${this.baseUrl}/pos/events`;
      await fetch(eventsUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type');
          if (response.status !== 200 || !contentType.includes('application/json')) {
            console.log(`Invalid response. Status: ${response.status} ContentType: ${contentType}`);
            self.showError();
            return;
          }
          return response.json();
        })
        .then(data => {
          // make sure we only return events with items lotted
          if (data) {
            return data.filter(row => row.items_ct > 0);
          }
        })
        .then(events => {
          if (!events) {
            self.showError();
            return;
          }
          self.auctions = events;
          if (self.auctions && self.auctions.length) {
            self.auction = self.auctions[0].name;
            self.currentAuctionId = self.auctions[0].id;
          }
        })
        .catch(function(err) {
          console.log('Fetch error:', err);
        });

    },
    async loadPhotos(id, index = 0) {
      const self = this;
      self.photos = self.images[id];
      setTimeout(() => {
        self.$refs.photos.open(index);
      }, 100);
    },
    async loadItems(page) {
      const self = this;
      if (!page) {
        page = self.page;
      } else {
        self.showPreloader = true;
      }

      const url = `${this.baseUrl}/pos/events/${self.currentAuctionId}/items?page=${page}&perpage=${self.perpage}`;
      await fetch(url)
        .then(response => {
          const contentType = response.headers.get('content-type');
          if (response.status !== 200) {
            console.log(`Invalid response. Status: ${response.status} ContentType: ${contentType}`);
            self.showError();
            return;
          }
          return response.json();
        })
        .then(response => {
          self.loading = false;
          self.page = response.page;
          self.perpage = response.perpage;
          self.count = response.count;

          for (let row of response.data) {
            const images = row.images.map(img => {
              return self.config.resizerPrefix + img;
            });
            self.images[row.id] = images;
          }

          if (self.items && self.items.length > 0)
            self.items = self.items.concat(response.data);
          else
            self.items = response.data;
        })
        .catch(err => {
          console.error(`Fetch error:`, err);
        });
    },
    async showError() {
      const self = this;
      self.showPreloader = false;
      self.loading = false;
      self.$f7.dialog.create({
        title: 'Something is broken',
        text: 'Please try again later',
      }).open();
    },
    async switchAuction(id) {
      const self = this;
      const auction = self.auctions.filter(row => row.id === id);
      if (auction && auction.length) {
        self.auction = auction[0].name;
        self.currentAuctionId = auction[0].id;
        self.posFinal = auction[0].pos_is_final;
        self.importantNotes = auction[0].pos_important_notes;
        await self.clearItems();
        setTimeout(async () => {
          await self.loadItems();
        }, 300);
      }
    },
    async clearItems() {
      const self = this;
      self.page = 1;
      self.items = [];
      self.showPreloader = true;
    },
    async loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      setTimeout(async () => {
        if (self.items && self.items.length >= self.count) {
          self.showPreloader = false;
          return;
        }
        const page = parseInt(self.page) + 1;
        await self.loadItems(page);
        self.allowInfinite = true;
      }, 1000);
    },
    async share() {
      try {
        await navigator.share({
          title: 'PicklesAuctions POS',
          text: 'Check out pos.pickles.my for all upcoming auctions items!',
          url: 'https://pos.pickles.my/',
        });
      } catch (err) {
        console.error('Error sharing', error);
      }
    },
  },
  computed: {
    canShare() {
      return navigator.share;
    },
  },
}
</script>
<style lang="scss">
div.page {
  background-color: #f6f6f6;
}
img.main {
  width: 100%;
  height: auto;
  display: block;
}
img.thumbs {
  width: 100%;
  height: 15vw;
}
div.vehicle-card-header2 {
  position: relative;
  height: 45vw;
  z-index: 1;
}
.vehicle-card-header2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: .2;
  filter: blur(1px);
  z-index: -2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--background-before);
}
.vehicle-card-header2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 200%;
  opacity: 1;
  z-index: -1;
  background-size: 75% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--background-after);
}
.device-android .vehicle-card-header2::after,
.device-ios .vehicle-card-header2::after {
  height: 300%;
}
.vehicle-card-header2 span {
  color: #fff;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-weight: bold;
  text-shadow:
    1px 1px 0 #999,
    1px -1px 0 #999,
    -1px 1px 0 #999,
    -1px -1px 0 #999;
}
span.lot-num {
  position: absolute;
  top: 0;
}
span.short-desc {
  text-align: center;
}
.auction-remarks .item-input-wrap pre {
  font-family: var(--f7-font-family)
}
.show-more {
  font-size: 2.5vw;
}
.vehicle-cart-footer {
  font-size: 30vw !important;
}
div.item-floating-label {
  color: #888 !important;
}
input.input-with-value {
  font-size: 2.5vw !important;
}
.pos-draft:before {
  content: "DRAFT";
}
.pos-final:before {
  content: "FINAL";
}
div.vehicle-details::before {
  position: absolute;
  top: 20%;
  left: -5%;
  transform: rotate(-45deg);
  width: 100vw;
  color: #999;
  opacity: 0.2;
  font-size: 15vw;
  text-align: center;
  height: auto;
  z-index: 100;
}
input.remarks {
  transform: translateX(100%);
  animation: scroll-left 30s linear infinite;
}
@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.auction-title {
  font-size: 3vw;
}
.auction-menu .list-button {
  font-size: 2.5vw;
}
.size-3vw {
  font-size: 3vw;
}
.popover .list {
  margin-top: 0 !important;
}
.toolbar-bottom {
  font-size: 2.5vw;
}
div.chip-media {
  background-color: #004c93 !important;
}
div.fab-right-bottom {
  zoom: 80%;
}
/* Custom fonts from pickles.com.au */
@font-face{
  font-family: 'pickles-custom-icon';
  src: url(https://pickles.azureedge.net/production/assets/toolkit/fonts/pickles-custom-icon.eot);
  src: url(https://pickles.azureedge.net/production/assets/toolkit/fonts/pickles-custom-icon.eot#iefix) format("embedded-opentype"),
    url(https://pickles.azureedge.net/production/assets/toolkit/fonts/pickles-custom-icon.ttf) format("truetype"),
    url(https://pickles.azureedge.net/production/assets/toolkit/fonts/pickles-custom-icon.woff) format("woff"),
    url(https://pickles.azureedge.net/production/assets/toolkit/fonts/pickles-custom-icon.svg#pickles-custom-icon) format("svg");
  font-style:normal;
}
[class*=' picon_'], [class*=' picon_']:before, [class^=picon_], [class^=picon_]:before {
  font-family:'pickles-custom-icon'!important;
  font-style:normal;
  // font-variant:normal;
  // text-transform:none;
  // font-size: 5vw;
  // line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
.picon_lg{font-size:120%}
.picon_2x{font-size:200%}
.picon_guarantee:before{content:'\e900'}
.picon_saved-search:before{content:'\e901'}
.picon_agricultural:before{content:'\e902'}
.picon_bike:before{content:'\e903'}
.picon_body:before{content:'\e904'}
.picon_car:before{content:'\e905'}
.picon_cars:before{content:'\e906'}
.picon_color:before{content:'\e907'}
.picon_condition-report:before{content:'\e908'}
.picon_construction:before{content:'\e909'}
.picon_engine:before{content:'\e90a'}
.picon_financing:before{content:'\e90b'}
.picon_fuel:before{content:'\e90c'}
.picon_general-goods:before{content:'\e90d'}
.picon_industrial:before{content:'\e90e'}
.picon_insurance:before{content:'\e90f'}
.picon_location:before{content:'\e910'}
.picon_machinery:before{content:'\e911'}
.picon_marine:before{content:'\e912'}
.picon_mileage:before{content:'\e913'}
.picon_mining:before{content:'\e914'}
.picon_pickles:before{content:'\e915'}
.picon_prestige:before{content:'\e916'}
.picon_recreational:before{content:'\e917'}
.picon_salvage:before{content:'\e918'}
.picon_transmission:before{content:'\e919'}
.picon_transportation:before{content:'\e91a'}
.picon_warranty:before{content:'\e91b'}
</style>
